"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCountryName = exports.COUNTRIES = exports.DEFAULT_COUNTRY_CODE = void 0;
exports.DEFAULT_COUNTRY_CODE = "CH";
/** All countries with German names. */
exports.COUNTRIES = [
    { code: "AF", de: "Afghanistan", en: "Afghanistan" },
    { code: "AX", de: "Åland", en: "Åland Islands" },
    { code: "AL", de: "Albanien", en: "Albania" },
    { code: "DZ", de: "Algerien", en: "Algeria" },
    { code: "AS", de: "Amerikanisch-Samoa", en: "American Samoa" },
    { code: "AD", de: "Andorra", en: "Andorra" },
    { code: "AO", de: "Angola", en: "Angola" },
    { code: "AI", de: "Anguilla", en: "Anguilla" },
    { code: "AQ", de: "Antarktis", en: "Antarctica" },
    { code: "AG", de: "Antigua und Barbuda", en: "Antigua and Barbuda" },
    { code: "AR", de: "Argentinien", en: "Argentina" },
    { code: "AM", de: "Armenien", en: "Armenia" },
    { code: "AW", de: "Aruba", en: "Aruba" },
    { code: "AU", de: "Australien", en: "Australia" },
    { code: "AT", de: "Österreich", en: "Austria" },
    { code: "AZ", de: "Aserbaidschan", en: "Azerbaijan" },
    { code: "BS", de: "Bahamas", en: "Bahamas" },
    { code: "BH", de: "Bahrain", en: "Bahrain" },
    { code: "BD", de: "Bangladesch", en: "Bangladesh" },
    { code: "BB", de: "Barbados", en: "Barbados" },
    { code: "BY", de: "Belarus", en: "Belarus" },
    { code: "BE", de: "Belgien", en: "Belgium" },
    { code: "BZ", de: "Belize", en: "Belize" },
    { code: "BJ", de: "Benin", en: "Benin" },
    { code: "BM", de: "Bermuda", en: "Bermuda" },
    { code: "BT", de: "Bhutan", en: "Bhutan" },
    { code: "BO", de: "Bolivien", en: "Bolivia (Plurinational State of)" },
    {
        code: "BQ",
        de: "Bonaire, Saba, Sint Eustatius",
        en: "Bonaire, Sint Eustatius and Saba",
    },
    { code: "BA", de: "Bosnien und Herzegowina", en: "Bosnia and Herzegovina" },
    { code: "BW", de: "Botswana", en: "Botswana" },
    { code: "BV", de: "Bouvetinsel", en: "Bouvet Island" },
    { code: "BR", de: "Brasilien", en: "Brazil" },
    {
        code: "IO",
        de: "Britisches Territorium im Indischen Ozean",
        en: "British Indian Ocean Territory",
    },
    { code: "BN", de: "Brunei", en: "Brunei Darussalam" },
    { code: "BG", de: "Bulgarien", en: "Bulgaria" },
    { code: "BF", de: "Burkina Faso", en: "Burkina Faso" },
    { code: "BI", de: "Burundi", en: "Burundi" },
    { code: "KH", de: "Kambodscha", en: "Cambodia" },
    { code: "CM", de: "Kamerun", en: "Cameroon" },
    { code: "CA", de: "Kanada", en: "Canada" },
    { code: "CV", de: "Kap Verde", en: "Cabo Verde" },
    { code: "KY", de: "Kaimaninseln", en: "Cayman Islands" },
    {
        code: "CF",
        de: "Zentralafrikanische Republik",
        en: "Central African Republic",
    },
    { code: "TD", de: "Tschad", en: "Chad" },
    { code: "CL", de: "Chile", en: "Chile" },
    { code: "CN", de: "Volksrepublik China", en: "China" },
    { code: "CX", de: "Weihnachtsinsel", en: "Christmas Island" },
    { code: "CC", de: "Kokosinseln", en: "Cocos (Keeling) Islands" },
    { code: "CO", de: "Kolumbien", en: "Colombia" },
    { code: "KM", de: "Komoren", en: "Comoros" },
    { code: "CG", de: "Kongo, Republik", en: "Congo" },
    {
        code: "CD",
        de: "Kongo, Demokratische Republik",
        en: "Congo, Democratic Republic of the",
    },
    { code: "CK", de: "Cookinseln", en: "Cook Islands" },
    { code: "CR", de: "Costa Rica", en: "Costa Rica" },
    { code: "CI", de: "Elfenbeinküste", en: "Côte d'Ivoire" },
    { code: "HR", de: "Kroatien", en: "Croatia" },
    { code: "CU", de: "Kuba", en: "Cuba" },
    { code: "CW", de: "Curaçao", en: "Curaçao" },
    { code: "CY", de: "Zypern", en: "Cyprus" },
    { code: "CZ", de: "Tschechien", en: "Czechia" },
    { code: "DK", de: "Dänemark", en: "Denmark" },
    { code: "DJ", de: "Dschibuti", en: "Djibouti" },
    { code: "DM", de: "Dominica", en: "Dominica" },
    { code: "DO", de: "Dominikanische Republik", en: "Dominican Republic" },
    { code: "EC", de: "Ecuador", en: "Ecuador" },
    { code: "EG", de: "Ägypten", en: "Egypt" },
    { code: "SV", de: "El Salvador", en: "El Salvador" },
    { code: "GQ", de: "Äquatorialguinea", en: "Equatorial Guinea" },
    { code: "ER", de: "Eritrea", en: "Eritrea" },
    { code: "EE", de: "Estland", en: "Estonia" },
    { code: "ET", de: "Äthiopien", en: "Ethiopia" },
    { code: "FK", de: "Falklandinseln", en: "Falkland Islands (Malvinas)" },
    { code: "FO", de: "Färöer", en: "Faroe Islands" },
    { code: "FJ", de: "Fidschi", en: "Fiji" },
    { code: "FI", de: "Finnland", en: "Finland" },
    { code: "FR", de: "Frankreich", en: "France" },
    { code: "GF", de: "Französisch-Guayana", en: "French Guiana" },
    { code: "PF", de: "Französisch-Polynesien", en: "French Polynesia" },
    {
        code: "TF",
        de: "Französische Süd- und Antarktisgebiete",
        en: "French Southern Territories",
    },
    { code: "GA", de: "Gabun", en: "Gabon" },
    { code: "GM", de: "Gambia", en: "Gambia" },
    { code: "GE", de: "Georgien", en: "Georgia" },
    { code: "DE", de: "Deutschland", en: "Germany" },
    { code: "GH", de: "Ghana", en: "Ghana" },
    { code: "GI", de: "Gibraltar", en: "Gibraltar" },
    { code: "GR", de: "Griechenland", en: "Greece" },
    { code: "GL", de: "Grönland", en: "Greenland" },
    { code: "GD", de: "Grenada", en: "Grenada" },
    { code: "GP", de: "Guadeloupe", en: "Guadeloupe" },
    { code: "GU", de: "Guam", en: "Guam" },
    { code: "GT", de: "Guatemala", en: "Guatemala" },
    { code: "GG", de: "Guernsey (Kanalinsel)", en: "Guernsey" },
    { code: "GN", de: "Guinea", en: "Guinea" },
    { code: "GW", de: "Guinea-Bissau", en: "Guinea-Bissau" },
    { code: "GY", de: "Guyana", en: "Guyana" },
    { code: "HT", de: "Haiti", en: "Haiti" },
    {
        code: "HM",
        de: "Heard und McDonaldinseln",
        en: "Heard Island and McDonald Islands",
    },
    { code: "VA", de: "Vatikanstadt", en: "Holy See" },
    { code: "HN", de: "Honduras", en: "Honduras" },
    { code: "HK", de: "Hongkong", en: "Hong Kong" },
    { code: "HU", de: "Ungarn", en: "Hungary" },
    { code: "IS", de: "Island", en: "Iceland" },
    { code: "IN", de: "Indien", en: "India" },
    { code: "ID", de: "Indonesien", en: "Indonesia" },
    { code: "IR", de: "Iran", en: "Iran (Islamic Republic of)" },
    { code: "IQ", de: "Irak", en: "Iraq" },
    { code: "IE", de: "Irland", en: "Ireland" },
    { code: "IM", de: "Insel Man", en: "Isle of Man" },
    { code: "IL", de: "Israel", en: "Israel" },
    { code: "IT", de: "Italien", en: "Italy" },
    { code: "JM", de: "Jamaika", en: "Jamaica" },
    { code: "JP", de: "Japan", en: "Japan" },
    { code: "JE", de: "Jersey (Kanalinsel)", en: "Jersey" },
    { code: "JO", de: "Jordanien", en: "Jordan" },
    { code: "KZ", de: "Kasachstan", en: "Kazakhstan" },
    { code: "KE", de: "Kenia", en: "Kenya" },
    { code: "KI", de: "Kiribati", en: "Kiribati" },
    {
        code: "KP",
        de: "Korea, Nord",
        en: "Korea (Democratic People's Republic of)",
    },
    { code: "KR", de: "Korea, Süd", en: "Korea, Republic of" },
    { code: "KW", de: "Kuwait", en: "Kuwait" },
    { code: "KG", de: "Kirgisistan", en: "Kyrgyzstan" },
    { code: "LA", de: "Laos", en: "Lao People's Democratic Republic" },
    { code: "LV", de: "Lettland", en: "Latvia" },
    { code: "LB", de: "Libanon", en: "Lebanon" },
    { code: "LS", de: "Lesotho", en: "Lesotho" },
    { code: "LR", de: "Liberia", en: "Liberia" },
    { code: "LY", de: "Libyen", en: "Libya" },
    { code: "LI", de: "Liechtenstein", en: "Liechtenstein" },
    { code: "LT", de: "Litauen", en: "Lithuania" },
    { code: "LU", de: "Luxemburg", en: "Luxembourg" },
    { code: "MO", de: "Macau", en: "Macao" },
    { code: "MK", de: "Nordmazedonien", en: "North Macedonia" },
    { code: "MG", de: "Madagaskar", en: "Madagascar" },
    { code: "MW", de: "Malawi", en: "Malawi" },
    { code: "MY", de: "Malaysia", en: "Malaysia" },
    { code: "MV", de: "Malediven", en: "Maldives" },
    { code: "ML", de: "Mali", en: "Mali" },
    { code: "MT", de: "Malta", en: "Malta" },
    { code: "MH", de: "Marshallinseln", en: "Marshall Islands" },
    { code: "MQ", de: "Martinique", en: "Martinique" },
    { code: "MR", de: "Mauretanien", en: "Mauritania" },
    { code: "MU", de: "Mauritius", en: "Mauritius" },
    { code: "YT", de: "Mayotte", en: "Mayotte" },
    { code: "MX", de: "Mexiko", en: "Mexico" },
    { code: "FM", de: "Mikronesien", en: "Micronesia (Federated States of)" },
    { code: "MA", de: "Marokko", en: "Morocco" },
    { code: "MD", de: "Moldau", en: "Moldova, Republic of" },
    { code: "MC", de: "Monaco", en: "Monaco" },
    { code: "MN", de: "Mongolei", en: "Mongolia" },
    { code: "ME", de: "Montenegro", en: "Montenegro" },
    { code: "MS", de: "Montserrat", en: "Montserrat" },
    { code: "MZ", de: "Mosambik", en: "Mozambique" },
    { code: "MM", de: "Myanmar", en: "Myanmar" },
    { code: "NA", de: "Namibia", en: "Namibia" },
    { code: "NR", de: "Nauru", en: "Nauru" },
    { code: "NP", de: "Nepal", en: "Nepal" },
    { code: "NL", de: "Niederlande", en: "Netherlands" },
    { code: "NC", de: "Neukaledonien", en: "New Caledonia" },
    { code: "NZ", de: "Neuseeland", en: "New Zealand" },
    { code: "NI", de: "Nicaragua", en: "Nicaragua" },
    { code: "NE", de: "Niger", en: "Niger" },
    { code: "NG", de: "Nigeria", en: "Nigeria" },
    { code: "NU", de: "Niue", en: "Niue" },
    { code: "NF", de: "Norfolkinsel", en: "Norfolk Island" },
    { code: "MP", de: "Nördliche Marianen", en: "Northern Mariana Islands" },
    { code: "NO", de: "Norwegen", en: "Norway" },
    { code: "OM", de: "Oman", en: "Oman" },
    { code: "PK", de: "Pakistan", en: "Pakistan" },
    { code: "PW", de: "Palau", en: "Palau" },
    { code: "PS", de: "Palästina", en: "Palestine, State of" },
    { code: "PA", de: "Panama", en: "Panama" },
    { code: "PG", de: "Papua-Neuguinea", en: "Papua New Guinea" },
    { code: "PY", de: "Paraguay", en: "Paraguay" },
    { code: "PE", de: "Peru", en: "Peru" },
    { code: "PH", de: "Philippinen", en: "Philippines" },
    { code: "PN", de: "Pitcairninseln", en: "Pitcairn" },
    { code: "PL", de: "Polen", en: "Poland" },
    { code: "PT", de: "Portugal", en: "Portugal" },
    { code: "PR", de: "Puerto Rico", en: "Puerto Rico" },
    { code: "QA", de: "Katar", en: "Qatar" },
    { code: "RE", de: "Réunion", en: "Réunion" },
    { code: "RO", de: "Rumänien", en: "Romania" },
    { code: "RU", de: "Russland", en: "Russian Federation" },
    { code: "RW", de: "Ruanda", en: "Rwanda" },
    { code: "BL", de: "Sankt Bartholomäus", en: "Saint Barthélemy" },
    {
        code: "SH",
        de: "St. Helena, Ascension und Tristan da Cunha",
        en: "Saint Helena, Ascension and Tristan da Cunha",
    },
    { code: "KN", de: "St. Kitts und Nevis", en: "Saint Kitts and Nevis" },
    { code: "LC", de: "St. Lucia", en: "Saint Lucia" },
    {
        code: "MF",
        de: "Saint-Martin (französischer Teil)",
        en: "Saint Martin (French part)",
    },
    {
        code: "PM",
        de: "Saint-Pierre und Miquelon",
        en: "Saint Pierre and Miquelon",
    },
    {
        code: "VC",
        de: "St. Vincent und die Grenadinen",
        en: "Saint Vincent and the Grenadines",
    },
    { code: "WS", de: "Samoa", en: "Samoa" },
    { code: "SM", de: "San Marino", en: "San Marino" },
    { code: "ST", de: "São Tomé und Príncipe", en: "Sao Tome and Principe" },
    { code: "SA", de: "Saudi-Arabien", en: "Saudi Arabia" },
    { code: "SN", de: "Senegal", en: "Senegal" },
    { code: "RS", de: "Serbien", en: "Serbia" },
    { code: "SC", de: "Seychellen", en: "Seychelles" },
    { code: "SL", de: "Sierra Leone", en: "Sierra Leone" },
    { code: "SG", de: "Singapur", en: "Singapore" },
    { code: "SX", de: "Sint Maarten", en: "Sint Maarten (Dutch part)" },
    { code: "SK", de: "Slowakei", en: "Slovakia" },
    { code: "SI", de: "Slowenien", en: "Slovenia" },
    { code: "SB", de: "Salomonen", en: "Solomon Islands" },
    { code: "SO", de: "Somalia", en: "Somalia" },
    { code: "ZA", de: "Südafrika", en: "South Africa" },
    {
        code: "GS",
        de: "Südgeorgien und die Südlichen Sandwichinseln",
        en: "South Georgia and the South Sandwich Islands",
    },
    { code: "SS", de: "Südsudan", en: "South Sudan" },
    { code: "ES", de: "Spanien", en: "Spain" },
    { code: "LK", de: "Sri Lanka", en: "Sri Lanka" },
    { code: "SD", de: "Sudan", en: "Sudan" },
    { code: "SR", de: "Suriname", en: "Suriname" },
    { code: "SJ", de: "Spitzbergen und Jan Mayen", en: "Svalbard and Jan Mayen" },
    { code: "SZ", de: "Eswatini", en: "Eswatini" },
    { code: "SE", de: "Schweden", en: "Sweden" },
    { code: "CH", de: "Schweiz", en: "Switzerland" },
    { code: "SY", de: "Syrien", en: "Syrian Arab Republic" },
    { code: "TW", de: "Republik China", en: "Taiwan, Province of China" },
    { code: "TJ", de: "Tadschikistan", en: "Tajikistan" },
    { code: "TZ", de: "Tansania", en: "Tanzania, United Republic of" },
    { code: "TH", de: "Thailand", en: "Thailand" },
    { code: "TL", de: "Osttimor", en: "Timor-Leste" },
    { code: "TG", de: "Togo", en: "Togo" },
    { code: "TK", de: "Tokelau", en: "Tokelau" },
    { code: "TO", de: "Tonga", en: "Tonga" },
    { code: "TT", de: "Trinidad und Tobago", en: "Trinidad and Tobago" },
    { code: "TN", de: "Tunesien", en: "Tunisia" },
    { code: "TR", de: "Türkei", en: "Turkey" },
    { code: "TM", de: "Turkmenistan", en: "Turkmenistan" },
    { code: "TC", de: "Turks- und Caicosinseln", en: "Turks and Caicos Islands" },
    { code: "TV", de: "Tuvalu", en: "Tuvalu" },
    { code: "UG", de: "Uganda", en: "Uganda" },
    { code: "UA", de: "Ukraine", en: "Ukraine" },
    {
        code: "AE",
        de: "Vereinigte Arabische Emirate",
        en: "United Arab Emirates",
    },
    {
        code: "GB",
        de: "Vereinigtes Königreich",
        en: "United Kingdom of Great Britain and Northern Ireland",
    },
    { code: "US", de: "Vereinigte Staaten", en: "United States of America" },
    {
        code: "UM",
        de: "United States Minor Outlying Islands",
        en: "United States Minor Outlying Islands",
    },
    { code: "UY", de: "Uruguay", en: "Uruguay" },
    { code: "UZ", de: "Usbekistan", en: "Uzbekistan" },
    { code: "VU", de: "Vanuatu", en: "Vanuatu" },
    { code: "VE", de: "Venezuela", en: "Venezuela (Bolivarian Republic of)" },
    { code: "VN", de: "Vietnam", en: "Viet Nam" },
    {
        code: "VG",
        de: "Britische Jungferninseln",
        en: "Virgin Islands (British)",
    },
    {
        code: "VI",
        de: "Amerikanische Jungferninseln",
        en: "Virgin Islands (U.S.)",
    },
    { code: "WF", de: "Wallis und Futuna", en: "Wallis and Futuna" },
    { code: "EH", de: "Westsahara", en: "Western Sahara" },
    { code: "YE", de: "Jemen", en: "Yemen" },
    { code: "ZM", de: "Sambia", en: "Zambia" },
    { code: "ZW", de: "Simbabwe", en: "Zimbabwe" },
];
function getCountryName(code, language) {
    const c = exports.COUNTRIES.find((c) => c.code === code.toUpperCase());
    if (c) {
        return c[language];
    }
    else {
        return undefined;
    }
}
exports.getCountryName = getCountryName;
