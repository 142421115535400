"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceAllDates = exports.replaceAllTimestamps = exports.isFirebaseTimestamp = exports.replaceAllIso = void 0;
const regexIso8601 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(\.\d+)?)?(Z|([-+])(\d{2}):(\d{2}))?$/;
/**
 * Recursively goes through `obj` and replaces all ISO string dates
 * by calling the `convertFn`.
 */
function replaceAllIso(obj, convertFn) {
    for (const i in obj) {
        if (obj[i] !== null) {
            if (typeof obj[i] === "string" && obj[i].match(regexIso8601)) {
                // Convert ISO date with converter function.
                const milliseconds = Date.parse(obj[i]);
                if (!isNaN(milliseconds)) {
                    obj[i] = convertFn(milliseconds);
                }
            }
            else if (typeof obj[i] === "object") {
                // Recursively traverse nested objects.
                replaceAllIso(obj[i], convertFn);
            }
        }
    }
}
exports.replaceAllIso = replaceAllIso;
/** Typeguard function to narrow down to `FirebaseTimestamp`. */
function isFirebaseTimestamp(obj) {
    if (typeof obj === "object" && obj !== null) {
        const o = obj;
        return (typeof o.seconds === "number" &&
            typeof o.nanoseconds === "number" &&
            typeof o.toDate === "function" &&
            typeof o.toMillis === "function");
    }
    return false;
}
exports.isFirebaseTimestamp = isFirebaseTimestamp;
/**
 * Recursively goes through `obj` and replaces all Firestore Timestamps
 * by calling the `convertFn`.
 */
function replaceAllTimestamps(obj, convertFn) {
    for (const i in obj) {
        if (obj[i] !== null) {
            // Find out if it is a Firestore Timestamps.
            // They have a property `seconds` (client) or `_seconds` (Cloud Functions) and a `toMillis()` function.
            if (isFirebaseTimestamp(obj[i])) {
                const milliseconds = obj[i].toMillis();
                obj[i] = convertFn(milliseconds);
            }
            else if (typeof obj[i] === "object") {
                // Recursively traverse nested objects.
                replaceAllTimestamps(obj[i], convertFn);
            }
        }
    }
}
exports.replaceAllTimestamps = replaceAllTimestamps;
/**
 * Recursively goes through `obj` and replaces all JavaScript Dates
 * by calling the `convertFn`.
 */
function replaceAllDates(obj, convertFn) {
    for (const i in obj) {
        if (obj[i] !== null) {
            if (obj[i] instanceof Date) {
                obj[i] = convertFn(obj[i]);
            }
            else if (typeof obj[i] === "object") {
                // Recursively traverse nested objects.
                replaceAllDates(obj[i], convertFn);
            }
        }
    }
}
exports.replaceAllDates = replaceAllDates;
