"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingStatus = void 0;
class BookingStatus {
    /** Make constructor private so we always use our single static instances. */
    constructor(name, nameDe) {
        this.name = name;
        this.nameDe = nameDe;
    }
    /** Factory to create a new BookingStatus from specified name. */
    static fromName(name) {
        const result = BookingStatus.allStatus.find((status) => status.name === name);
        if (result) {
            return result;
        }
        else {
            throw new Error(`Unknown BookingStatus: ${name}`);
        }
    }
    /**
     * Returns true if the status is a resource in busy state.
     * Returns false if the resource is free.
     */
    isBusy() {
        return BookingStatus.allBusyStatus.includes(this);
    }
}
exports.BookingStatus = BookingStatus;
/** Booked is the status for simple bookings (without customer). */
BookingStatus.booked = new BookingStatus("booked", "gebucht");
/** Every booking request from a customer starts in the provisional state. */
BookingStatus.provisional = new BookingStatus("provisional", "provisorisch");
/** Status when the team confirms the provisional booking. */
BookingStatus.confirmed = new BookingStatus("confirmed", "bestätigt");
/** The customer or team cancelled the booking. The resource is free, an invoice might still be sent. */
BookingStatus.cancelled = new BookingStatus("cancelled", "annulliert");
/** All possible booking status. */
BookingStatus.allStatus = [
    BookingStatus.booked,
    BookingStatus.provisional,
    BookingStatus.confirmed,
    BookingStatus.cancelled,
];
/** All status where the resource is in a busy state. */
BookingStatus.allBusyStatus = [
    BookingStatus.booked,
    BookingStatus.provisional,
    BookingStatus.confirmed,
];
BookingStatus.allCustomerBookingStatus = [
    BookingStatus.provisional,
    BookingStatus.confirmed,
    BookingStatus.cancelled,
];
BookingStatus.allCustomerBookingBusyStatus = [
    BookingStatus.provisional,
    BookingStatus.confirmed,
];
