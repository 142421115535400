"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigDiscount = void 0;
const jsonUtils_1 = require("benjibooks-shared/utils/jsonUtils");
/**
 * The `ConfigDiscount` is used to define discounts in the configuration.
 *
 * @see `BookingDiscount` for the corresponding model that is used in the booking.
 *
 * The `ConfigDiscount` has two variants: a percentage or a monetary amount.
 *
 * **Percentage**
 * Example: `{title = "Geheimer Rabatt", percent = 20}`
 *
 * **Monetary Amount**
 * Example: `{title = "Geheimer Rabatt", amount = 50}`
 *
 * **Optional Discount Code**
 * Example: `{title = "Geheimer Rabatt", percent = 20, code = "secret"}`
 */
class ConfigDiscount {
    /**
     * Constructor.
     */
    constructor() {
        this.title = "";
    }
    /**
     * Constructs an object of this class from the specified JSON-compatible dictionary object.
     */
    static fromJSON(json) {
        const obj = new ConfigDiscount();
        Object.assign(obj, json);
        return obj;
    }
    /**
     * Converts this object to JSON-compatible dictionary object.
     * All fields from `this` will be in the returned object except the `undefined` values.
     */
    toJSON() {
        const json = (0, jsonUtils_1.toJSONWithFilters)(this, {
            filterUndefined: true,
        });
        return json;
    }
    /** Returns true, a percent is specified. If false, an amount is specified. */
    isPercent() {
        if (this.percent !== undefined) {
            return true;
        }
        else if (this.amount !== undefined) {
            return false;
        }
        else {
            throw new Error("Invalid booking discount: no percent and no amount specified.");
        }
    }
    /** Returns the value of this discount. Can either be a percent or an amount value. */
    getValue() {
        if (this.isPercent()) {
            return this.percent;
        }
        else {
            return this.amount;
        }
    }
}
exports.ConfigDiscount = ConfigDiscount;
