"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validEmail = void 0;
/**
 * Returns true if the email address is valid.
 */
function validEmail(email) {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
}
exports.validEmail = validEmail;
