"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnv = void 0;
/** Production. */
const prod = {
    envName: "prod",
    baseUrl: "https://www.benjibooks.com",
    firebaseConfig: {
        apiKey: "AIzaSyBKEZCo3zj77boRiI7jPs2DmFRJ8_bGy9g",
        authDomain: "benjibooks-prod.firebaseapp.com",
        databaseURL: "https://benjibooks-prod.firebaseio.com",
        projectId: "benjibooks-prod",
        storageBucket: "benjibooks-prod.appspot.com",
        messagingSenderId: "707246735292",
        appId: "1:707246735292:web:d4dbe242a14865506b35b9",
        measurementId: "G-HEBCWGRF9P",
    },
};
/** Development. */
const dev = {
    envName: "dev",
    // baseUrl: "http://localhost:5001/benjibooks-dev/us-central1",
    baseUrl: "https://dev.benjibooks.com",
    firebaseConfig: {
        apiKey: "AIzaSyAd6R5tavjfWNhNrCEOMMHaSk-Lmw72mx0",
        authDomain: "benjibooks-dev.firebaseapp.com",
        databaseURL: "https://benjibooks-dev.firebaseio.com",
        projectId: "benjibooks-dev",
        storageBucket: "benjibooks-dev.appspot.com",
        messagingSenderId: "229605527316",
        appId: "1:229605527316:web:14df3dc5bae2ed39ebbc01",
        measurementId: "G-30S8PBK5F7",
    },
};
function getEnv() {
    if (FIREBASE_ENV === "prod" || location.hostname === "www.benjibooks.com") {
        return prod;
    }
    else {
        return dev;
    }
}
exports.getEnv = getEnv;
