"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryStringForDate = exports.useQueryStringForBoolean = exports.useQueryStringForNumberArray = exports.useQueryStringForNumber = exports.useQueryStringForStringArray = exports.useQueryStringForString = void 0;
const query_string_1 = require("query-string");
const react_1 = require("react");
const dateUtils = __importStar(require("./dateUtils"));
// Part of this code is inspird by Fernando Abolafio.
// https://medium.com/swlh/using-react-hooks-to-sync-your-component-state-with-the-url-query-string-81ccdfcb174f
/**
 * Hook to keep a **string** in sync with the URL query string.
 *
 * @param key the key used to identify our state value in the query string.
 * @param defaultValue the default value used if there isn't a value for the
 * provided key among the query string parameter yet.
 * @param validate optional validate function to not end up with non-sense values
 * in our state. If initial query string is invalid the default value is used.
 */
function useQueryStringForString(key, defaultValue, validate = (value) => true) {
    const encode = (valueObj) => valueObj;
    const decode = (queryStrValue) => {
        // Check if value is of type string and is valid.
        return typeof queryStrValue === "string" && validate(queryStrValue)
            ? queryStrValue
            : defaultValue;
    };
    return useQueryStringInit(key, defaultValue, encode, decode);
}
exports.useQueryStringForString = useQueryStringForString;
/**
 * Hook to keep a **string array** in sync with the URL query string.
 *
 * @param key the key used to identify our state value in the query string.
 * @param defaultValue the default value used if there isn't a value for the
 * provided key among the query string parameter yet.
 * @param arrayFormat optional array format.
 * @param validate optional validate function to not end up with non-sense values
 * in our state. If initial query string is invalid the default value is used.
 */
function useQueryStringForStringArray(key, defaultValue, arrayFormat = "none", validate = (value) => true) {
    const encode = (valueObj) => valueObj;
    const decode = (queryStrValue) => {
        // Check if value is of type string and is valid.
        const parsedValueArray = Array.isArray(queryStrValue)
            ? queryStrValue
            : [queryStrValue];
        // Check if all values in the array are of type string (not undefined, not null).
        return parsedValueArray.every((pv) => typeof pv === "string") &&
            validate(parsedValueArray)
            ? parsedValueArray
            : defaultValue;
    };
    return useQueryStringInit(key, defaultValue, encode, decode, arrayFormat);
}
exports.useQueryStringForStringArray = useQueryStringForStringArray;
/**
 * Hook to keep some a **number** in sync with the URL query string.
 *
 * @param key the key used to identify our state value in the query string.
 * @param defaultValue the default value used if there isn't a value for the
 * provided key among the query string parameter yet.
 * @param validate optional validate function to not end up with non-sense values
 * in our state. If initial query string is invalid the default value is used.
 */
function useQueryStringForNumber(key, defaultValue, validate = (value) => true) {
    const encode = (valueObj) => valueObj.toString();
    const decode = (queryStrValue) => {
        // Check if value is of type number and is valid.
        if (!Number.isNaN(Number(queryStrValue)) &&
            typeof queryStrValue === "string" &&
            queryStrValue.trim() !== "") {
            const num = Number(queryStrValue);
            return validate(num) ? num : defaultValue;
        }
        else {
            return defaultValue;
        }
    };
    return useQueryStringInit(key, defaultValue, encode, decode);
}
exports.useQueryStringForNumber = useQueryStringForNumber;
/**
 * Hook to keep a **number array** in sync with the URL query string.
 *
 * @param key the key used to identify our state value in the query string.
 * @param defaultValue the default value used if there isn't a value for the
 * provided key among the query string parameter yet.
 * @param arrayFormat optional array format.
 * @param validate optional validate function to not end up with non-sense values
 * in our state. If initial query string is invalid the default value is used.
 */
function useQueryStringForNumberArray(key, defaultValue, arrayFormat = "none", validate = (value) => true) {
    const encode = (valueObj) => valueObj.map((vo) => vo.toString());
    const decode = (queryStrValue) => {
        const parsedValueArray = Array.isArray(queryStrValue)
            ? queryStrValue
            : [queryStrValue];
        // Check if all values in the array are of type string (not undefined, not null).
        if (parsedValueArray.every((pn) => !Number.isNaN(Number(pn)) &&
            typeof pn === "string" &&
            pn.trim() !== "")) {
            const parsedNumArray = parsedValueArray.map((pv) => Number(pv));
            return validate(parsedNumArray) ? parsedNumArray : defaultValue;
        }
        else {
            return defaultValue;
        }
    };
    return useQueryStringInit(key, defaultValue, encode, decode, arrayFormat);
}
exports.useQueryStringForNumberArray = useQueryStringForNumberArray;
/**
 * Hook to keep a **boolean** in sync with the URL query string.
 *
 * @param key the key used to identify our state value in the query string.
 * @param defaultValue the default value used if there isn't a value for the
 * provided key among the query string parameter yet.
 */
function useQueryStringForBoolean(key, defaultValue) {
    const encode = (valueObj) => (valueObj ? "true" : "false");
    const decode = (queryStrValue) => queryStrValue === "true";
    return useQueryStringInit(key, defaultValue, encode, decode);
}
exports.useQueryStringForBoolean = useQueryStringForBoolean;
/**
 * Hook to keep a **Date** in sync with the URL query string.
 *
 * @param key the key used to identify our state value in the query string.
 * @param defaultValue the default value used if there isn't a value for the
 * provided key among the query string parameter yet.
 * @param format optional date format. Default is "yyyy-MM-dd".
 * @param validate optional validate function to not end up with non-sense values
 * in our state. If initial query string is invalid the default value is used.
 */
function useQueryStringForDate(key, defaultValue, format = "yyyy-MM-dd", validate = (value) => true) {
    const encode = (valueObj) => dateUtils.formatDate(valueObj, { format });
    const decode = (queryStrValue) => {
        if (typeof queryStrValue === "string") {
            const date = dateUtils.parseDate(queryStrValue, format);
            if (!Number.isNaN(date.getTime())) {
                return validate(date) ? date : defaultValue;
            }
        }
        return defaultValue;
    };
    return useQueryStringInit(key, defaultValue, encode, decode);
}
exports.useQueryStringForDate = useQueryStringForDate;
/**
 * Initially sets the query string to the provided value and
 * uses state to keep the URL in sync with any changes.
 *
 * @param key the key used to identify our state value in the query string.
 */
function useQueryStringInit(key, defaultValue, encode, decode, arrayFormat) {
    // Use value with state.
    const [value, setValue] = (0, react_1.useState)(() => {
        // Set initial value from query string if available.
        return getQueryStringValue(key, defaultValue, decode, arrayFormat);
    });
    // Use a callback to avoid our function to be redefined in every re-render.
    const onSetValue = (0, react_1.useCallback)((action) => {
        if (action instanceof Function) {
            setValue((prevState) => {
                const val = action(prevState);
                setQueryStringValue(key, encode(val), false, arrayFormat);
                return val;
            });
        }
        else {
            setQueryStringValue(key, encode(action), false, arrayFormat);
            setValue(action);
        }
    }, [key]);
    (0, react_1.useEffect)(() => {
        function setFromQueryString() {
            setValue(getQueryStringValue(key, defaultValue, decode, arrayFormat));
        }
        // Set up listener for browser history changes.
        window.addEventListener("popstate", setFromQueryString);
        return () => window.removeEventListener("popstate", setFromQueryString);
    }, []);
    return [value, onSetValue];
}
/** Keep track of the key sort order. The number is the position the key should appear in the query string. */
const keySortOrder = {};
/** Sort function to sort keys in the order that `setQueryStringValue` is called. */
const keySortFn = (itemLeft, itemRight) => {
    var _a, _b;
    const left = (_a = keySortOrder[itemLeft]) !== null && _a !== void 0 ? _a : -1;
    const right = (_b = keySortOrder[itemRight]) !== null && _b !== void 0 ? _b : -1;
    return left - right;
};
/**
 * Get value from location.search.
 */
function getQueryStringValue(key, defaultValue, decode, arrayFormat) {
    const parsedQuery = (0, query_string_1.parse)(location.search, { arrayFormat })[key];
    if (typeof parsedQuery === "string" ||
        (Array.isArray(parsedQuery) &&
            parsedQuery.every((p) => typeof p === "string"))) {
        return decode(parsedQuery);
    }
    else {
        return defaultValue;
    }
}
/**
 * Updates the query string parameters only for the provided key,
 * keeping the remaining parameters intact.
 *
 * If `pushState` is set to false, no new browser history state is pushed.
 */
function setQueryStringValue(key, value, pushState = true, arrayFormat) {
    // Add key to sort order if it does not exist.
    if (keySortOrder[key] === undefined) {
        keySortOrder[key] = Object.keys(keySortOrder).length;
    }
    const values = (0, query_string_1.parse)(location.search, { arrayFormat });
    const newQsValue = (0, query_string_1.stringify)({ ...values, [key]: value }, { encode: false, sort: keySortFn, arrayFormat });
    // Set the new query string value.
    const newUrl = `${location.protocol}//${location.host}${location.pathname}?${newQsValue}`;
    if (pushState) {
        history.pushState({}, "", newUrl);
    }
    else {
        history.replaceState({}, "", newUrl);
    }
}
