"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollToTop = void 0;
const react_1 = require("react");
// Component that scrolls to top.
// Renders nothing, just attaches side effects.
const ScrollToTop = () => {
    // Run the effect only once.
    (0, react_1.useEffect)(() => {
        window.scrollTo(0, 0);
    }, []);
    // renders nothing, since nothing is needed
    return null;
};
exports.ScrollToTop = ScrollToTop;
