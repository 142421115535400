"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadAllTenants = exports.loadTenants = void 0;
const firestore_1 = require("firebase/firestore");
const Tenant_1 = require("../Tenant");
async function loadTenants(firestore, tenantIds) {
    return await Promise.all(tenantIds.map(async (tenantId) => {
        const tenantSnapshot = await (0, firestore_1.getDoc)((0, firestore_1.doc)(firestore, "tenants", tenantId));
        const tenantData = tenantSnapshot.data();
        if (tenantData) {
            const tenant = Tenant_1.Tenant.fromJSON(tenantId, tenantData);
            return tenant;
        }
        else {
            throw new Error(`Tenant not found: tenantId=${tenantId}`);
        }
    }));
}
exports.loadTenants = loadTenants;
/** Loads all tenants from the database. */
async function loadAllTenants(firestore) {
    const tenantSnapshot = await (0, firestore_1.getDocs)((0, firestore_1.collection)(firestore, "tenants"));
    return tenantSnapshot.docs.map((doc) => {
        return Tenant_1.Tenant.fromJSON(doc.id, doc.data());
    });
}
exports.loadAllTenants = loadAllTenants;
