"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tenant = void 0;
const jsonUtils_1 = require("benjibooks-shared/utils/jsonUtils");
/**
 * The tenant (in Firestore db).
 */
class Tenant {
    constructor({ id, name }) {
        this.id = id;
        this.name = name;
    }
    /**
     * Constructs an object of this class from the specified JSON-compatible dictionary object.
     */
    static fromJSON(id, json) {
        if (typeof json.name !== "string") {
            throw new Error("Tenant name is undefined.");
        }
        const obj = new Tenant({ id, name: json.name });
        Object.assign(obj, json);
        return obj;
    }
    /**
     * Converts this object to JSON-compatible dictionary object.
     * All fields from `this` will be in the returned object except the `undefined` values.
     */
    toJSON() {
        const json = (0, jsonUtils_1.toJSONWithFilters)(this, {
            filterUndefined: true,
            filterId: true,
        });
        return json;
    }
}
exports.Tenant = Tenant;
