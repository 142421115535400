// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\" style=\"fill:gray;\" width=\"18\" height=\"18\" viewBox=\"0 0 24 24\"><path d=\"M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z\"/><path d=\"M0 0h24v24H0z\" fill=\"none\"/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".breadcrumb{padding:0;list-style:none;background-color:rgba(0,0,0,0);border-radius:.25rem;font-size:14px}.breadcrumb-item+.breadcrumb-item::before{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");overflow:hidden;vertical-align:middle;padding-top:2px;padding-right:.2rem}.breadcrumb-item,.breadcrumb-item a{color:#6c757d}.breadcrumb-item.active{color:#212529}.breadcrumb-item+.breadcrumb-item{padding-left:.2rem}", "",{"version":3,"sources":["webpack://./src/components/breadcrumbs/Breadcrumbs.scss"],"names":[],"mappings":"AAEA,YACE,SAAA,CACA,eAAA,CACA,8BAAA,CACA,oBAAA,CACA,cAAA,CAGF,0CACE,+CAAA,CACA,eAAA,CACA,qBAAA,CACA,eAAA,CACA,mBAAA,CAIA,oCAEE,aAAA,CAGF,wBACE,aAAA,CAIJ,kCACE,kBAAA","sourcesContent":["@import \"../../variables\";\r\n\r\n.breadcrumb {\r\n  padding: 0;\r\n  list-style: none;\r\n  background-color: transparent;\r\n  border-radius: 0.25rem;\r\n  font-size: 14px;\r\n}\r\n\r\n.breadcrumb-item + .breadcrumb-item::before {\r\n  content: url('data:image/svg+xml;utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\" style=\"fill:gray;\" width=\"18\" height=\"18\" viewBox=\"0 0 24 24\"><path d=\"M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z\"/><path d=\"M0 0h24v24H0z\" fill=\"none\"/></svg>');\r\n  overflow: hidden;\r\n  vertical-align: middle;\r\n  padding-top: 2px;\r\n  padding-right: 0.2rem;\r\n}\r\n\r\n.breadcrumb-item {\r\n  &,\r\n  a {\r\n    color: #6c757d;\r\n  }\r\n\r\n  &.active {\r\n    color: #212529;\r\n  }\r\n}\r\n\r\n.breadcrumb-item + .breadcrumb-item {\r\n  padding-left: 0.2rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
