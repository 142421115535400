"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingContact = void 0;
const jsonUtils_1 = require("benjibooks-shared/utils/jsonUtils");
const countryUtils = __importStar(require("../utils/countryUtils"));
/**
 * The `BookingContact` is a property of `CustomerBooking`.
 */
class BookingContact {
    constructor() {
        this.org = "";
        this.name = "";
        /** Address may be multiple lines containing c/o or other address info in addition to the street. */
        this.address = "";
        this.zip = "";
        this.city = "";
        this.country = countryUtils.DEFAULT_COUNTRY_CODE;
        this.phone = "";
        this.email = "";
    }
    /**
     * Returns the full address block with name, address, zip, city, etc.
     *
     * If the country is not the default country, the country name is
     * added on the last line.
     */
    getAddressBlock({ includeOrg = true, includeName = true, defaultCountry = countryUtils.DEFAULT_COUNTRY_CODE, countryLanguage = "de", } = {}) {
        if (this.isLegacyAddress()) {
            return this.legacyAddress_getAddressBlock();
        }
        else {
            let result = "";
            if (includeOrg && this.org) {
                result += this.org + "\n";
            }
            if (includeName && this.name) {
                result += this.name + "\n";
            }
            result += this.address + "\n" + this.zip + " " + this.city;
            if (this.country !== defaultCountry) {
                result +=
                    "\n" + countryUtils.getCountryName(this.country, countryLanguage);
            }
            return result;
        }
    }
    /**
     * Creates a new contact based on the `prevContact` with updated fields
     * from the partial `newContact`.
     */
    static fromPartial(newContact, prevContact) {
        return Object.assign(new BookingContact(), prevContact, newContact);
    }
    static fromJSON(json) {
        const obj = new BookingContact();
        Object.assign(obj, json);
        return obj;
    }
    toJSON() {
        const json = (0, jsonUtils_1.toJSONWithFilters)(this, {
            filterUndefined: true,
            filterEmptyString: true,
        });
        return json;
    }
    isLegacyAddress() {
        return this.address && !this.zip;
    }
    legacyAddress_getAddressBlock() {
        return this.address;
    }
    /**
     * The legacy address holds the entire address block including
     * org, name, zip, city, and any additional info.
     *
     * This parser tris to extract the zip code and city name.
     *
     * It removes org, name, zip, city from the address block and sets
     * the cleaned address.
     *
     * The result is NOT RELIABLE and is a best guess. The legacy address is a free
     * text entered by the user. So we have no guarantee that our parser will work.
     */
    legacyAddress_parse() {
        let resultAddress = "";
        this.address.split("\n").forEach((line) => {
            if (line.trim() === this.org.trim() || line.trim() === this.name.trim()) {
                // Line contains org or name. Ignore it.
                return;
            }
            const zipCityMatch = line.match(/(\d{4,8})\s+(.+)/);
            if (zipCityMatch) {
                // Found zip and city. Save it separately but don't add it to resulting address.
                this.zip = zipCityMatch[1];
                this.city = zipCityMatch[2];
            }
            else {
                // Add to resulting address.
                if (resultAddress) {
                    resultAddress += "\n";
                }
                resultAddress += line;
            }
        });
        this.address = resultAddress.trim();
    }
}
exports.BookingContact = BookingContact;
