"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBackToLastLocation = void 0;
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
/**
 * Hook to go back to the last location if it is available from our application.
 *
 * A last location is not available if the user came from outside our app. In this
 * case the default location is used.
 */
function useBackToLastLocation(defaultLocation) {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const handleGoBack = (0, react_1.useCallback)(() => {
        // Go back to last history state if available from our application.
        if (location.key && location.key !== "default") {
            navigate(-1);
        }
        else {
            // Not available because user might have come from outside our app.
            navigate(defaultLocation, { replace: true });
        }
    }, [document.referrer]);
    return {
        goBack: handleGoBack,
    };
}
exports.useBackToLastLocation = useBackToLastLocation;
