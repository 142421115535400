"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthInfo = void 0;
const User_1 = require("benjibooks-shared/models/User");
class AuthInfo {
    constructor({ loading = false } = {}) {
        this.loading = loading;
    }
    /**
     * Set the user from Firebase Auth. If user signed out, the Firebase user
     * will be undefined.
     */
    static async fromFirebaseUser(firebaseUser) {
        const obj = new AuthInfo({ loading: false });
        obj.firebaseUser = firebaseUser;
        await obj.setUserFromFirebaseUser();
        return obj;
    }
    async setUserFromFirebaseUser() {
        var _a, _b;
        if (this.firebaseUser) {
            // Create and set the user.
            this.user = new User_1.User({
                id: this.firebaseUser.uid,
                email: (_a = this.firebaseUser.email) !== null && _a !== void 0 ? _a : "",
                name: (_b = this.firebaseUser.displayName) !== null && _b !== void 0 ? _b : "",
            });
            // Load custom claims from Firebase Auth.
            const idTokenResult = await this.firebaseUser.getIdTokenResult();
            const claims = idTokenResult.claims;
            this.user.setCustomClaims(claims);
        }
    }
    isSignedIn() {
        return this.firebaseUser !== undefined;
    }
    /**
     * Returns a authentication header with `Bearer` token.
     * If the user is not signed in, an empty string is returned.
     */
    async getAuthHeader() {
        if (this.firebaseUser) {
            return `Bearer ${await this.firebaseUser.getIdToken()}`;
        }
        else {
            return "";
        }
    }
    /**
     * Forces a refresh to get the newest name/email and custom claims from the server.
     */
    async reload() {
        if (this.firebaseUser) {
            await this.firebaseUser.reload();
            await this.setUserFromFirebaseUser();
            // Force refresh the token to trigger `onIdTokenChanged` event.
            await this.firebaseUser.getIdToken(true);
        }
    }
}
exports.AuthInfo = AuthInfo;
