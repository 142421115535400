"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePrompt = void 0;
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
/**
 * Blocks and shows a prompt if the user tries to navigate away from the current page.
 *
 * Note: the `unstable_usePrompt` does not seem to work in all cases in Chrome (e.g. when
 * the user clicks on browser back button).
 *
 * @see https://claritydev.net/blog/display-warning-for-unsaved-form-data-on-page-exit
 */
function usePrompt({ when, message, }) {
    const onLocationChange = (0, react_1.useCallback)(({ nextLocation }) => {
        if (when) {
            return !window.confirm(message);
        }
        return false;
    }, [message, when]);
    const blocker = (0, react_router_dom_1.unstable_useBlocker)(when ? onLocationChange : false);
    const prevState = (0, react_1.useRef)(blocker.state);
    (0, react_1.useEffect)(() => {
        if (blocker.state === "blocked") {
            blocker.reset();
        }
        prevState.current = blocker.state;
    }, [blocker]);
    (0, react_router_dom_1.useBeforeUnload)((0, react_1.useCallback)((event) => {
        if (when) {
            event.preventDefault();
            event.returnValue = "";
        }
    }, [when]), { capture: true });
}
exports.usePrompt = usePrompt;
