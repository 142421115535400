"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceStatus = void 0;
class InvoiceStatus {
    /** Make constructor private so we always use our single static instances. */
    constructor(name, nameDe) {
        this.name = name;
        this.nameDe = nameDe;
    }
    /** Factory to create a new status from specified name. */
    static fromName(name) {
        const result = InvoiceStatus.allStatus.find((status) => status.name === name);
        if (result) {
            return result;
        }
        else {
            throw new Error(`Unknown InvoiceStatus: ${name}`);
        }
    }
}
exports.InvoiceStatus = InvoiceStatus;
/** Initial status for all invoices. Invoice hasn't been sent to customer. */
InvoiceStatus.none = new InvoiceStatus("none", "keine Rechnung");
/** The invoice has been sent to the client (invoice/due dates are set). Awaiting payment. */
InvoiceStatus.open = new InvoiceStatus("open", "offen");
/** Customer made a partial payment or paid too much. Outstanding balance is not 0. */
InvoiceStatus.partial = new InvoiceStatus("partial", "teilbezahlt");
/** Customer fully paid the invoice. Outstanding balance is 0. */
InvoiceStatus.paid = new InvoiceStatus("paid", "bezahlt");
/** The total balance due of the invoice is 0. No payment necessary. */
InvoiceStatus.zero = new InvoiceStatus("zero", "Nullbetrag");
/** The invoice has been voided (for example by rejecting the booking). */
InvoiceStatus.void = new InvoiceStatus("void", "storniert");
/** All possible status. */
InvoiceStatus.allStatus = [
    InvoiceStatus.none,
    InvoiceStatus.open,
    InvoiceStatus.partial,
    InvoiceStatus.paid,
    InvoiceStatus.zero,
    InvoiceStatus.void,
];
/** All status where the invoice should be visible to the customer. */
InvoiceStatus.allInvoiceVisibleStatus = [
    InvoiceStatus.open,
    InvoiceStatus.partial,
    InvoiceStatus.paid,
];
